<template>
    <div class="">
        <div class="card-producto cr-pointer" @click="abrirDetalleProducto">
            <figure class="card-producto__figure bg-whitesmoke mr-2 my-0">
                <img :src="producto.imagen_firmada" alt="" class="card-producto__img" style="width:38px; height: 53px;" />
            </figure>
            <div class="card-producto__detalles">
                <p class="card-producto__titulo text-general f-12 tres-puntos">{{ producto.sku }} - {{ producto.nombre }} </p>
                <p class="card-producto__presentacion f-12">{{ producto.presentacion }}</p>
                <div class="d-flex aling-items-center justify-content-between">
                    <div class="card-producto__precio-descuento text-start">
                        <div class="d-flex align-items-center">
                            <p class="card-producto__precio text-general f-12 f-500 mr-3">{{ producto.id_promocion ? separadorNumero(producto.promo_valor) : separadorNumero(producto.valor) }}</p>
                            <p v-if="producto.id_promocion" class="card-producto__precio_descuento f-12 f-500">{{ separadorNumero(producto.valor) }}</p>
                        </div>
                        <p v-if="producto.id_promocion" class="card-producto__descuento bg-leeche cr-pointer">{{ producto.texto }}</p>
                    </div>
                    <div @click.stop>
                        <el-input-number v-model="producto.cantidad" :min="1" :max="Infinity" size="mini" class="card-producto__contador " @change="gestionRecurrenteProducto(producto)" />
                    </div>
                </div>
            </div>
        </div>
        <modal-detalles-pedidos ref="verDetallesPedidos" @agregarProducto="agregarProducto" @eliminarProducto="eliminarProducto" />
    </div>
</template>

<script>

import PedidosRecurrentes from '~/services/almacen/pedidos_recurrentes'
export default {
    components:{
        modalDetallesPedidos: () => import('../partials/modalDetallePedidos')

    },
    props:{
        producto:{
            type:Object,
            default: () => null
        },
        pedidoActivo:{
            type:Number,
            default: 0
        },
    },
    data(){
        return{
            valorUnidad : 0
        }
    },
    methods:{
        agregarProducto(val){
            this.$emit('agregarProducto', val)
            this.producto.cantidad = val.producto.cantidad
        },
        eliminarProducto(val){
            console.log(val,'card');
            this.$emit('eliminarProducto', val)
        },
        async gestionRecurrenteProducto(producto){
            try {
                let model = {
                    id_recurrente: this.pedidoActivo,
                    id_producto: producto.id,
                    cantidad: producto.cantidad,
                }
                const {data} = await PedidosRecurrentes.gestionRecurrenteProducto(model)
                this.notificacion('Mensaje', data.message, 'success')
                // this.activa = !this.activa
                this.$emit('agregarProducto', data.data)

            } catch (e){
                this.error_catch(e)
            }
        },
        abrirDetalleProducto(){
            this.$refs.verDetallesPedidos.toggle(this.producto,this.pedidoActivo)
        }
    }
}
</script>
<style lang="scss" scoped>
     .card-producto{
        width: 336px;
        height: 89px;
        display: flex;
        align-items: center;
        margin:  8px;
        &__img{
            object-fit: contain;
        }
        &__figure{
            width: 89px;
            height: 89px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 12px;
        }
        &__detalles{
            text-align: start;
            padding: 5px;
            width: 228px;
        }
        &__titulo{
            margin: 0 !important;
            word-break: break-word;
        }
        &__presentacion{
            color: #707070;
        }
        &__descuento{
            border-radius: 22px;
            font-size: 11px;
            color: #fff;
            height: 17px;
            text-align: center;
            text-transform: uppercase;
            padding:  2px 10px 0;
            width: fit-content;
        }
        &__contador{
            width: 85px !important;
        }
        &__precio_descuento{
            color: #dee2e6;
            text-decoration: line-through;
        }
    }

    /*-*-*-*-*-*-*-* Media Queries -*-*-*-*-*-*-*/
    @media(max-width:1000px){
        .card-producto{
            flex-wrap: wrap;            
            width: auto !important;
            height: auto !important;
            justify-content: center;
        }
    }
</style>
